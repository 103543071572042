import { useBannerContext } from "@/context/banner";
import { translatedErrors } from "./translatedErrors";

export function useDisplayError() {
  const { showBanner } = useBannerContext();

  const displayError = (errors: string[], resetOnNavigation = true) =>
    showBanner({
      message: errors
        .map(error =>
          Object.keys(translatedErrors).includes(error)
            ? translatedErrors[error]
            : error,
        )
        .join(", "),
      variation: "error",
      resetOnNavigation: resetOnNavigation,
    });

  return { displayError };
}
