import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Auth } from "@aws-amplify/auth";
import { Content } from "@jobber/components/Content";
import { showToast } from "@jobber/components/Toast";
import { useDisplayError } from "@/hooks/useDisplayError";
import { useUserContext } from "@/context/user";
import { ErrorCodes } from "@/utils/cognito/ErrorCodes";
import { MFAWizard } from "./MFAWizard";

export function MFAWizardLoader() {
  const { displayError } = useDisplayError();
  const { username, cognitoUser, setMFAEnabled } = useUserContext();
  const [QRString, setQRString] = useState("");
  const [textCode, setTextCode] = useState("");
  const [TOTPInputError, setTOTPInputError] = useState("");
  useEffect(() => {
    generateQrString();
  }, [cognitoUser]);

  return (
    <Content>
      <MFAWizard
        code={{ textCode: textCode, QRString: QRString }}
        invalidInput={TOTPInputError ? true : false}
        onVerifyTOTP={onVerifyTOTP}
      />
    </Content>
  );

  async function generateQrString() {
    try {
      await Auth.setupTOTP(cognitoUser).then(code => {
        setTextCode(code);
        setQRString(
          "otpauth://totp/AWSCognito:" +
            username +
            "?secret=" +
            code +
            "&issuer=" +
            "Jobber Developer Center",
        );
      });
    } catch (error: any) {
      // The only error that occurs is when CognitoUser is null
      return;
    }
  }

  async function onVerifyTOTP(qrAnswer: string) {
    try {
      await Auth.verifyTotpToken(cognitoUser, qrAnswer);
      await Auth.setPreferredMFA(cognitoUser, "TOTP");
      showToast({
        message: "Two-factor authentication enabled.",
        variation: "success",
      });
      setMFAEnabled(true);
      navigate("/settings");
    } catch (error: any) {
      switch (error.code) {
        case ErrorCodes.ENABLE_SOFTWARE_TOKEN_MFA_EXCEPTION:
          setTOTPInputError(
            "Authentication code is incorrect, please try again.",
          );
          displayError(["Authentication code is incorrect, please try again."]);
          break;
        case ErrorCodes.INVALID_PARAMETER_EXCEPTION:
          setTOTPInputError(
            "Authentication code needs to be the 6 digit code as shown in the authentication app.",
          );
          displayError([
            "Authentication code needs to be the 6 digit code as shown in the authentication app.",
          ]);
          break;
        default:
          displayError([error.message]);
          setTOTPInputError(error.message);
      }
    }
  }
}
