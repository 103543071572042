import React, { useEffect, useRef } from "react";
import { Banner as BannerComponent } from "@jobber/components/Banner";
import { useBannerContext } from "@/context/banner";
import * as styles from "./Banner.module.css";

interface Props {
  className?: string;
}

export function Banner(props: Props) {
  const ref = useRef<null | HTMLDivElement>(null);
  const {
    message,
    type,
    visible,
    viewed,
    primaryAction,
    hideBanner,
    viewBanner,
  } = useBannerContext();

  useEffect(() => {
    if (visible && !viewed) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      viewBanner();
    }
  }, [visible, viewed]);

  return (
    <>
      {visible ? (
        <div className={props.className} ref={ref}>
          <div className={styles.container}>
            <BannerComponent
              type={type}
              onDismiss={hideBanner}
              primaryAction={primaryAction}
            >
              {message}
            </BannerComponent>
          </div>
        </div>
      ) : null}
    </>
  );
}
