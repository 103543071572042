import React, { useEffect, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { InputText } from "@jobber/components/InputText";
import { Text } from "@jobber/components/Text";
import { Form } from "@jobber/components/Form";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Modal } from "@jobber/components/Modal";
import { QRCodeSVG } from "qrcode.react";
import * as commonStyles from "@/features/Authentication/Common/Common.module.css";
import * as linkStyles from "@/components/Link/Link.module.css";
import { Banner } from "@/components/Banner";

interface MFAWizardProps {
  code: { textCode: string; QRString: string };
  invalidInput: boolean;
  onVerifyTOTP(qrAnswer: string): void;
}

export function MFAWizard({
  code: { textCode, QRString },
  invalidInput,
  onVerifyTOTP,
}: MFAWizardProps) {
  const [qrAnswer, setQrAnswer] = useState("");
  const [isTextCodeOpen, setIsTextCodeOpen] = useState(false);
  const [isBrowser, setIsBrowser] = useState(false);
  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return (
    <>
      <div className={commonStyles.common_wrapper}>
        <Banner />
        <Card>
          <Content>
            <Heading level={2}>Set up Two-Factor Authentication</Heading>
            <Text size="large">
              Add two-factor authentication to increase the security of your
              account.
            </Text>
            <Text>
              {
                "Scan the following code using an app such as Google Authenticator on your phone to receive a code. If you can't scan the image, "
              }
              <a
                style={{ cursor: "pointer" }}
                className={linkStyles.link}
                onClick={() => setIsTextCodeOpen(true)}
              >
                enter this text code
              </a>{" "}
              instead.
            </Text>
            {isBrowser && (
              <Modal
                title="Your two-factor secret"
                open={isTextCodeOpen}
                onRequestClose={() => setIsTextCodeOpen(false)}
              >
                <Content>{textCode}</Content>
              </Modal>
            )}
          </Content>
          <div className={commonStyles.padding_wrapper}>
            <Form onSubmit={handleVerifyTOTP}>
              <Content>
                {QRString && (
                  <div className={commonStyles.center_align}>
                    <QRCodeSVG value={QRString} />
                  </div>
                )}
                <Text>Enter the 6 digit code displayed in the app below:</Text>
                <InputText
                  placeholder="Authentication code"
                  onChange={(value: string) => {
                    setQrAnswer(value);
                  }}
                  invalid={invalidInput}
                />
                <Button label="Continue" size="large" fullWidth submit />
              </Content>
            </Form>
          </div>
        </Card>
      </div>
    </>
  );

  function handleVerifyTOTP() {
    onVerifyTOTP(qrAnswer);
  }
}
