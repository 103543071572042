import React from "react";
import { MFAWizard } from "@/features/Settings/MFAWizard";
import { Page } from "@/components/Page";
import { withAuthentication } from "@/features/Authentication/AccessRestriction";

function MFASetup() {
  return (
    <Page variation="session">
      <MFAWizard />
    </Page>
  );
}

export default withAuthentication(MFASetup);
